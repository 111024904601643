import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cdb5c92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "$_www-northcyprustopinvest_404_page_$",
  class: "fullscreen bg-accent2 text-white text-center q-pa-md column justify-center items-center no-wrap"
}
const _hoisted_2 = {
  key: 0,
  class: "p-bounce-animation"
}
const _hoisted_3 = {
  key: 1,
  class: "p-color-animation q-mb-lg"
}
const _hoisted_4 = { style: {"text-align":"left"} }
const _hoisted_5 = { class: "q-mt-sm text-weight-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$q.screen.gt.sm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3)),
    _createElementVNode("div", {
      class: _normalizeClass(["text-text q-mb-md", {
                'text-h3': _ctx.$q.screen.gt.sm,
                'text-h4 q-mb-md': _ctx.$q.screen.lt.md
            }])
    }, _toDisplayString(_ctx.$t('not_found_error.title')), 3),
    _createVNode(_component_q_card, {
      class: _normalizeClass(["bg-accent3 text-text", {
                'bg-accent': !_ctx.$q.dark.isActive,
                'bg-accent3': _ctx.$q.dark.isActive
            }]),
      style: {"max-width":"500px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, { class: "column justify-start items-start" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('not_found_error.message.content')), 1),
            _createElementVNode("span", _hoisted_5, " - " + _toDisplayString(_ctx.$t('not_found_error.message.author')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_q_btn, {
      "aria-label": _ctx.$t('not_found_error.try_again'),
      class: "q-mt-xl",
      color: "text",
      "text-color": "accent",
      unelevated: "",
      to: "/",
      label: _ctx.$t('not_found_error.try_again'),
      "no-caps": ""
    }, null, 8, ["aria-label", "label"])
  ]))
}